<template>  
  <div class="card h-100">
    <!--begin::Header-->
    <!-- <div class="card-header border-0">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-5 mb-1 cor_texto_1">
            Histórico de contato
        </span>
        <span class="fw-bold fs-7 cor_texto_2">{{ periodoTitle }}</span>
      </h3>
    </div> -->

    <div class="card-header border-0 py-0">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder mb-1 cor_texto_1">
          Histórico de contato
        </span>
      </h3>
    </div>
    <!--end::Header-->
    <div class="card-body py-3">
      <div class="d-flex" v-if="!loadingTable && seriesRef.every(serie => !serie)">
        <p>Nenhum contato foi encontrado</p>
      </div>

      <div class="align-self-center h-225px" :class="{'d-none': loadingTable || seriesRef.every(serie => !serie)}">
        <div id="grafico-historico"/>
      </div>

      <div class="d-flex" v-if="loadingTable">
        <ContentLoader :animate="true" viewBox="0 0 400 200" v-if="loadingTable">
          <rect x="38" y="130" rx="4" ry="4" width="22" height="40" /> 
          <rect x="66" y="115" rx="4" ry="4" width="22" height="55" /> 
          <rect x="96" y="95" rx="4" ry="4" width="22" height="74" /> 
          <rect x="128" y="50" rx="4" ry="4" width="22" height="120" /> 
          <rect x="157" y="111" rx="4" ry="4" width="22" height="58" /> 
          <rect x="189" y="26" rx="4" ry="4" width="22" height="143" /> 
          <rect x="221" y="135" rx="4" ry="4" width="22" height="35" /> 
          <rect x="251" y="92" rx="4" ry="4" width="22" height="78" /> 
          <rect x="283" y="116" rx="4" ry="4" width="22" height="54" /> 
          <rect x="317" y="65" rx="4" ry="4" width="22" height="105" /> 
          <rect x="349" y="135" rx="4" ry="4" width="22" height="35" />
        </ContentLoader>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
import { defineComponent, onMounted, ref, watch } from "vue";
import ApexChart from "apexcharts";

export default defineComponent({
  name: "HistoricoContatoCard",
  components: { ContentLoader },
  props: {
    data: Array,
    periodoTitle: String,
    loadingTable: Boolean,
  },
  setup(props) {
    const seriesRef = ref([]);
    watch(() => props.data, ( ) => {
      if(props.data){
        let historicoContato = props.data.map(contato => contato.quantidade);
        historicoContato.pop();
        seriesRef.value = historicoContato;
        // console.log("Historico contato", seriesRef.value);
        setTimeout(() => {
          chartInstance.updateSeries(geraSeries(historicoContato));
        }, 500);
      }
    });

    function geraOptions(categories) {
      const options = {
        xaxis: {  
          categories,
          labels: {
            style: {
              colors: 'var(--cor_texto_1)',
            },
          },
        },
        chart: {
          zoom: {
            enabled: false,
          },
          width: '107%',
          height: 230,
          series: "line",
          stacked: true,
          toolbar: {
            show: false
          },
          offsetY: -40,    
          offsetX: -15,    
        },
        fill: {  
          type: 'solid',
          colors: 'var(--cor_indicadores_2)',
          
        },
        colors:['var(--cor_indicadores_2)'],
        yaxis: [
            {
              axisTicks: {
                show: true,
                
              },
              axisBorder: {
                show: false,
                color: 'var(--cor_indicadores_2)'
              },
              labels: {
                style: {
                  colors: 'var(--cor_texto_1)',
                },
              },
              tooltip: {
                enabled: false
              }
            },
        ],
        plotOptions: {
          bar: {
            endingShape: 'rounded',
            columnWidth: '35%',
            borderRadius:10,
            style: {
              colors: 'var(--cor_indicadores_2)',
            },
          }
        }
      };
      return options;
    };

    function geraSeries(refSeries){
      const series = [ 
        {
          name: 'Contatos realizados',
          type: 'column',
          data: refSeries,
        },
      ]

      return series;
    };

    let chartInstance;
    onMounted(async () => {
      const options = geraOptions(["08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"]);
      const series = geraSeries([]);

      chartInstance = new ApexChart(document.querySelector("#grafico-historico"), {
        ...options,
        series,
      });
      chartInstance.render();

      // await faturamentoDosMeses(14,"2021-04-01/2021-05-31"); //editar
    });

    // watch(() => props.historicoOrcamentos, () => {
    //   // 2021-04-01/2021-05-31 É um exemplo com dados
    //   console.log('props',props.historicoOrcamentos);
    //   faturamentoDosMeses(props.historicoOrcamentos.series, props.historicoOrcamentos.categorias);
    // });


    // watch(() => props.filters, () => {
    //   // 2021-04-01/2021-05-31 É um exemplo com dados

    //   // faturamentoDosMeses(14,"2021-04-01/2021-05-31");//editar
    // });

      // function renderGrafico(){
      //   setTimeout(() => {
      //     chartInstance.updateOptions(options.value);
      //     chartInstance.updateSeries(series.value);
      //   }, 500)
      // }

    return {
      seriesRef
      //  renderGrafico,
    };
  }
});

</script>