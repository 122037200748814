
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from 'vue';
import Datatable from "@/components/datatable/Datatable.vue";
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import PlacaModal from "@/components/modals/geral/PlacaModal.vue";
import { getItensReprovados } from "@/services/OficinaDigital";
import { enviarMensagem } from "@/services/GeralService";
import { useRouter } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import useAlert from "@/composables/Alert";
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import XLSX from 'xlsx-js-style';
import useEmitter from '@/composables/Emmiter';
import { Modal } from "bootstrap";
import moment from 'moment';
import { hideModal } from '@/core/helpers/dom';
import MotivosReprovacaoCard from './components/MotivosReprovacaoCard.vue';
import HistoricoContatoCard from './components/HistoricoContatoCard.vue';
import store from "@/store/index";

export default defineComponent({
  name: "itens-reprovados",
  components: {
    Datatable,
    MessageModal,
    PlacaModal,
    ElConfigProvider,
    MotivosReprovacaoCard,
    HistoricoContatoCard
  },
  setup() {
    const historicoContatoPeriodo = ref("");
    const emitter = useEmitter();
    const dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");
    const { showConfirmAlert, showTimeAlert } = useAlert();
    const codConcessionaria = ref();
    let animate = ref(true)

    emitter.on("filtrar-od-reprovados", async data  => {
			codConcessionaria.value = data.codConcessionaria;

      const postData = {
        motivosReprovacao: [...data.filtrosReprovados.motivosReprovacao],
        itens: [...data.filtrosReprovados.pecas],
        placa: data.filtrosReprovados.placa,
      };

      showBtnSendSMS.value = false;
      showBtnSendEmail.value = false;
       await listaItensReprovados(data.data, postData, data.filtrosReprovados.statusNegociacao); 
    });

    // Declara variável para simular array vazio
    const emptyArray: any[] = [];

    // Declara filtros
    const pecaSelected:any = ref(emptyArray)
    const motivoSelected:any = ref(emptyArray)

    const showBtnSendSMS:any = ref(false)
    const showBtnStatusSendSMS:any = ref(false)
    const showBtnSendEmail:any = ref(false)
    const showBtnStatusSendEmail:any = ref(false)
    const showBtnConfirmarSendSMS:any = ref(false)
    const showBtnConfirmarSendEmail:any = ref(false)
    const loadingButton:any = ref(false)
    const showSelect:any = ref(true)

    const dadosMotivoReprovacao = ref(emptyArray);
    const dadosHistoricoContato = ref(emptyArray);

    const itensReprovados = ref(emptyArray);
    const itensSelecteds = ref(emptyArray);    

    // Lógica do formulário (Declaração dos vmodels / Validação)
    const simpleSchema = {
      formTelefone(value) {
        if (value && value.trim()) {
          return true;
        }
        return "* Necessário preencher o campo telefone";
      },

      formEmail(value) {
        if (value && value.trim()) {
          return true;
        }
        return "* Necessário preencher o campo email";
      },
    };

    useForm({
      validationSchema: simpleSchema,
    });

    const { value: formTelefone, errorMessage: telefoneError } = useField<string>('formTelefone');
    const { value: formEmail, errorMessage: emailError } = useField<string>('formEmail');
    const formMessage = ref("");
    const formMessageAll = ref("");

    // Lógica de controle de roteamento  
    const router = useRouter(); 
    function goTo(url){
			router.push(url);
		};

    // Lógica dos dados da tabela
    const loadingTable = ref(true);
    const headers = [
      {
        name: "Cliente",
        key: "cliente",
        sortable: false,
      },
      {
        name: "Placa",
        key: "placa",
        sortable: false,
      },
      {
        name: "Telefone",
        key: "telefone",
        sortable: false,
      },
      {
        name: "Item",
        key: "item",
        sortable: false,
      },
      {
        name: "Reprovação",
        key: "dataReprovacao",
        sortable: false,
      },
      {
        name: "Motivo Reprovação",
        key: "motivoReprovacao",
        sortable: false,
      },
      // {
      //   name: "Pré-Agendamento",
      //   key: "preAgendamentoContato",
      //   sortable: false,
      // },
      {
        name: "Aceita Negociação",
        key: "statusNegociacao",
        sortable: false,
      },
      {
        name: "Ações",
        key: "invoice",
        sortable: false,
      },
    ];
    
    const headersSMS = [
      {
        name: "Status",
        key: "invoice",
        sortable: false,
      },
      {
        name: "Cliente",
        key: "cliente",
        sortable: false,
      },
      {
        name: "Telefone",
        key: "telefone",
        sortable: false,
      },
    ];

    const headersEmail = [
      {
        name: "Status",
        key: "invoice",
        sortable: false,
      },
      {
        name: "Cliente",
        key: "cliente",
        sortable: false,
      },
      {
        name: "Email",
        key: "email",
        sortable: false,
      },
    ];

    const data = reactive({
      subheader: "",
      itemSelected: {
        marca: "",
        codConcessionaria: "",
        codChecklist: "",
        checklist: "",
        cliente: "",
        telefone: "",
        item: "",
        dataReprovacao: "",
        motivoReprovacao: "",
        preAgendamentoContato: "",
        codOrcamento: "",
        envioSMS: false,
        envioWPP: false,
        envioEmail: false,
        envioSMSData: false,
        envioWhatsappData: false,
        envioEmailData: false,
        info: {
          placa: "",
          cliente: "",
          modelo: "",
          email: "",
          telefone: "",
          consultor: "",
          mecanico: "",
          dataReprovacao: "",
        },
      },
    });

    const itensReprovadosToXLSX = ref(emptyArray);

    function geraDadosGraficos(itensReprovadosRef){
      const historicoContato = [
        {
          horario: "08",
          quantidade: 0
        },
        {
          horario: "09",
          quantidade: 0
        },
        {
          horario: "10",
          quantidade: 0
        },
        {
          horario: "11",
          quantidade: 0
        },
        {
          horario: "12",
          quantidade: 0
        },
        {
          horario: "13",
          quantidade: 0
        },
        {
          horario: "14",
          quantidade: 0
        },
        {
          horario: "15",
          quantidade: 0
        },
        {
          horario: "16",
          quantidade: 0
        },
        {
          horario: "17",
          quantidade: 0
        },
        {
          horario: "18",
          quantidade: 0
        },
        {
          horario: "Outros",
          quantidade: 0
        },
      ];

      const motivosReprovacao = [
        {
          nome: "Desejo negociar",
          quantidade: 0
        },
        {
          nome: "Preço",
          quantidade: 0
        },
        {
          nome: "Não Desejo Este Serviço",
          quantidade: 0
        },
        {
          nome: "Realizar em Outra Loja",
          quantidade: 0
        },
        {
          nome: "Realizar na Próxima Revisão",
          quantidade: 0
        },
        {
          nome: "Garantia",
          quantidade: 0
        },
        {
          nome: "Outros",
          quantidade: 0
        },
      ];

      itensReprovadosRef.forEach(item => {
        const refMotivo = motivosReprovacao.find((motivo) => motivo.nome == item.motivoReprovacao);
        if(refMotivo){
          refMotivo.quantidade++ 
        } else {
          // Outros
          motivosReprovacao[5].quantidade++
        }; 

        const horarioContatoWpp:any = item.envioWhatsappData ? moment(item.envioWhatsappData, "DD/MM/YYYY HH:mm:ss") : "";
        const horarioContatoSms:any = item.envioSMSData ? moment(item.envioSMSData, "DD/MM/YYYY HH:mm:ss") : "";

        if(horarioContatoWpp){
          // Verifica se o contato foi hoje
          if(horarioContatoWpp.isSame(moment(), "day") || true){ 
            const refHorario = historicoContato.find((horario) => horario.horario == horarioContatoWpp.hour());
            if(refHorario){
              refHorario.quantidade++ 
            } else {
              // Outros
              historicoContato[11].quantidade++
            };
          }
        }

        if(horarioContatoSms){
          // Verifica se o contato foi hoje
          if(horarioContatoSms.isSame(moment(), "day") || true){
            const refHorario = historicoContato.find((horario) => horario.horario == horarioContatoSms.hour());
            if(refHorario){
              refHorario.quantidade++ 
            } else {
              // Outros
              historicoContato[11].quantidade++
            };
          }
        }
      });
      dadosMotivoReprovacao.value = [...motivosReprovacao];
      dadosHistoricoContato.value = [...historicoContato];
    }

    async function listaItensReprovados(filters = "", postData, statusNegociacao = []){
    animate.value = true
      if(!filters){
        moment.locale('pt-br');
        const startMounth = moment().startOf("month");
        const now = moment();
        historicoContatoPeriodo.value = `${startMounth.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")} até ${now.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
      } else {
        const periodoSelecionado = filters.split("/");
        const dataInicial = moment(periodoSelecionado[0], "YYYY-MM-DD HH:mm:ss");
        const dataFinal = moment(periodoSelecionado[1], "YYYY-MM-DD HH:mm:ss");

        if(dataInicial.isSame(dataFinal, "day") && moment().isSame(dataInicial, "day")){
          historicoContatoPeriodo.value = `hoje`;
        } else if (dataInicial.isSame(dataFinal, "day")) {
          historicoContatoPeriodo.value = `${dataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
        } else {
          historicoContatoPeriodo.value = `${dataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
        }
      }

      loadingTable.value = true;
      pecaSelected.value = [];
      motivoSelected.value = [];

      let response = await getItensReprovados(codConcessionaria.value, filters, postData);
      console.log("response reprovados", response)
      if(statusNegociacao.length > 0 && statusNegociacao.length != 2){
        if(statusNegociacao[0]){
          response = response.filter(itemReprovado => itemReprovado.statusNegociacao == "1" || itemReprovado.statusNegociacao == "2");
        } else {
          response = response.filter(itemReprovado => itemReprovado.statusNegociacao == "0");
        }
      }

      geraDadosGraficos(response);

      if(!data.subheader){
        const nomeConcessionaria = response[0]?.nomeFantasia ? response[0]?.nomeFantasia : "";
        const codFornecedor = response[0]?.codAtria ? " - " + response[0]?.codAtria : "";
        data.subheader = `${nomeConcessionaria}${codFornecedor}`;
      };

      itensReprovados.value = response.map((itemReprovado) => {
        const statusNegociacao = itemReprovado.statusNegociacao == "1" || itemReprovado.statusNegociacao == "2" ? "Sim" : "Não"; 
        return {
          marca: itemReprovado.dadosChecklist.marca,
          codConcessionaria: itemReprovado.codConcessionaria,
          codChecklist: itemReprovado.codChecklist,
          codOrcamento: itemReprovado.codOrcamento,
          checklist: itemReprovado.checklist,
          cliente: itemReprovado.dadosCliente.cliente,
          telefone: itemReprovado.dadosCliente.telefone,
          item: itemReprovado.item,
          dataReprovacao: itemReprovado.dataReprovacao,
          motivoReprovacao: itemReprovado.motivoReprovacao,
          preAgendamentoContato: itemReprovado.contatoPreAgendamento,
          envioSMS: Number(itemReprovado.envioSMS) > 0,
          envioSMSData: itemReprovado.envioSMSData,
          envioWPP: Number(itemReprovado.envioWhatsapp) > 0,
          envioWhatsappData: itemReprovado.envioWhatsappData,
          envioEmail: Number(itemReprovado.envioEmail) > 0,
          envioEmailData: itemReprovado.envioEmailData,
          statusNegociacao: statusNegociacao,
          info: {
            placa: itemReprovado.dadosCliente.placa,
            cliente: itemReprovado.dadosCliente.cliente,
            modelo: itemReprovado.dadosCliente.modelo,
            email: itemReprovado.dadosCliente.email,
            telefone: itemReprovado.dadosCliente.telefone,
            consultor: itemReprovado.dadosCliente.consultor,
            mecanico: itemReprovado.dadosCliente.mecanico,
            dataReprovacao: itemReprovado.dataReprovacao,
          },
        };
      });

      itensReprovadosToXLSX.value = [...itensReprovados.value].map((item) => {
        return {
          "Item": item.item,
          "Cliente": item.cliente,
          "Modelo": item.info.modelo,
          "E-mail": item.info.email,
          "Telefone": item.telefone,
          "Consultor": item.info.consultor,
          "Mecânico": item.info.mecanico,
          "Data Reprovação": item.dataReprovacao,
          "Motivo Reprovação": item.motivoReprovacao,
          "Pré-Agendamento Contato": item.preAgendamentoContato,
          "Aceita Negociação": item.statusNegociacao
        }
      }) 

      loadingTable.value = false;
      animate.value = false
    };

    let blob = ref();
    emitter.on("geraXLSX", () => {
      gerarXLSX();
    });

    emitter.on("exportXLSX", () => {
      exportarXLSX();
    });

    function gerarXLSX() {
      let wb: XLSX.WorkBook = XLSX.utils.book_new();
      wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2017,12,19)
      };
      wb.SheetNames.push("MO");

      const ws = XLSX.utils.json_to_sheet(itensReprovadosToXLSX.value.length == 0 ? [
        {
          "Item": "",
          "Cliente": "",
          "Modelo": "",
          "E-mail": "",
          "Telefone": "",
          "Consultor": "",
          "Mecânico": "",
          "Data Reprovação": "",
          "Motivo Reprovação": "",
          "Pré-Agendamento Contato": "",
          "Aceita Negociação": ""
        }
      ] : itensReprovadosToXLSX.value);
      const wscols = [
        {wch:45},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
      ]
      ws['!cols'] = wscols;

      ws["A1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["B1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["C1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["D1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["E1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["F1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["G1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["H1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["I1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["J1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }
      ws["K1"].s = {
        fill: {
          fgColor: { rgb: "FFD6D8DB" },
	      },
      }

      wb.Sheets["MO"] = ws;

      function s2ab(s) { 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
      }

      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

      blob.value = new Blob([s2ab(wbout)], {type: 'text/plain;charset=UTF-8'});
    };

    function exportarXLSX(){
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob.value);
        link.setAttribute('href', url);
        link.setAttribute('download', "export_itens_reprovados.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);  
      };
    };

    async function enviarSMS (defaultMessage = false){      
      let telefone = defaultMessage ? data.itemSelected.telefone : formTelefone.value;
      telefone = telefone.replaceAll(" ", ""); //retira espaços ex: (31)33333333
      telefone = telefone.replaceAll("-", ""); //retira espaços ex: (31)33333333
			telefone = telefone.replaceAll(")", ""); //retira parenteses ex: (3133333333
			telefone = telefone.replaceAll("(", ""); //retira
      
      const mensagem = defaultMessage ? "" : formMessage.value;
     console.log(telefone)
      const postData = {
        marca: data.itemSelected.marca,
        telefone: telefone,
        mensagem: mensagem ? mensagem : null,
        cliente: data.itemSelected.cliente,
        codConcessionaria: data.itemSelected.codConcessionaria,
        codChecklist: data.itemSelected.codChecklist,
        checklist: data.itemSelected.checklist,
        codOrcamento: data.itemSelected.codOrcamento,
        tipo: 1,
      };
      
      loadingButton.value = true;
      try{
        await enviarMensagem(postData);
        const elementModal = document.getElementById("modal-sms");
        hideModal(elementModal);
        showTimeAlert('O SMS foi enviado!');
        data.itemSelected.envioSMS = true;
      } catch (e){
        showTimeAlert('Ocorreu um erro ao enviar o SMS', 'error');
      }
      loadingButton.value = false;
    };

    async function enviarEmail (defaultMessage = false){      
      let email = defaultMessage ? data.itemSelected.info.email : formEmail.value;
      const mensagem = defaultMessage ? "" : formMessage.value;

      const postData = {
        marca: data.itemSelected.marca,
        email: email,
        mensagem: mensagem ? mensagem : null,
        cliente: data.itemSelected.cliente,
        codConcessionaria: data.itemSelected.codConcessionaria,
        codChecklist: data.itemSelected.codChecklist,
        checklist: data.itemSelected.checklist,
        codOrcamento: data.itemSelected.codOrcamento,
        tipo: 4,
      };
      
      loadingButton.value = true;
      try{
        await enviarMensagem(postData);
        const elementModal = document.getElementById("modal-email");
        hideModal(elementModal);
        showTimeAlert('O email foi enviado!');
        data.itemSelected.envioEmail = true;
      } catch (e){
        showTimeAlert('Ocorreu um erro ao enviar o email', 'error');
      }
      loadingButton.value = false;
    };

    function resetSelectSMS (){
      showBtnConfirmarSendSMS.value = false;
      showBtnStatusSendSMS.value = false;
      showSelect.value = true;
      showSelect.value = true;
      formMessageAll.value = "";
    };

    function resetSelectEmail (){
      showBtnConfirmarSendEmail.value = false;
      showBtnStatusSendEmail.value = false;
      showSelect.value = true;
      formMessageAll.value = "";
    };

    function geraMessage (item){
      const message = `Olá sr(a) ${item.cliente}, o item ${item.item} foi reprovado por você, posso lhe oferecer condições especiais para aprovação.`;

      return message;
    }

    const clientesSemEmail = ref(emptyArray);
    const clientesSemTelefone = ref(emptyArray);

    function filtraClientes(){
      const arrayClientesSemEmail = itensSelecteds.value.filter(cliente => !cliente.info.email)
      if(arrayClientesSemEmail.length === itensSelecteds.value.length){
        showBtnSendEmail.value = false;
      }
      clientesSemEmail.value = arrayClientesSemEmail.map(cliente => cliente.cliente).filter((este, i) => arrayClientesSemEmail.map(cliente => cliente.cliente).indexOf(este) === i)

      const arrayclientesSemTelefone =  itensSelecteds.value.filter(cliente => !cliente.telefone)
      if(arrayclientesSemTelefone.length === itensSelecteds.value.length){
        showBtnSendSMS.value = false;
      }
      clientesSemTelefone.value = arrayclientesSemTelefone.map(cliente => cliente.cliente).filter((este, i) => arrayclientesSemTelefone.map(cliente => cliente.cliente).indexOf(este) === i)
    };

    async function enviarAllSMS (defaultMessage = false){
      const refModal = document.getElementById("modal-status-all-sms");
      if(refModal){
        document.getElementById("layout_content")?.appendChild(refModal);
      }
      modalStatusAllSms.value = new Modal(refModal, {keyboard: false, backdrop: 'static'});
      modalStatusAllSms.value.show();

      showSelect.value = false;
      showBtnSendSMS.value = false;
      showBtnStatusSendSMS.value = true;
      itensSelecteds.value = itensSelecteds.value.filter(item => item.telefone).map((item) => ({ ...item, status: "Pendente" }));

      // let varAux = 1000;

      await itensSelecteds.value.forEach((item, index) => {
        item.telefone = item.telefone.replaceAll(" ", "").replaceAll("-", "").replaceAll(")", "").replaceAll("(", "");
        
        const mensagem = defaultMessage ? null : formMessageAll.value;

        const postData = {
          marca: item.marca,
          telefone: item.telefone,
          mensagem: mensagem ? mensagem : null,
          cliente: item.cliente,
          codConcessionaria: item.codConcessionaria,
          codChecklist: item.codChecklist,
          codOrcamento: data.itemSelected.codOrcamento,
          checklist: item.checklist,
          tipo: 1,
        };

        // varAux += 1000;

        enviarMensagem(postData).then(() => item.status = "Realizado").catch(() => item.status = "Erro");
        // item.status = "Realizado"
        if(itensSelecteds.value.length - 1 == index){
          showBtnConfirmarSendSMS.value = true;

          showTimeAlert('Os SMS foram enviados!');
        };
      });
    };

    const modalStatusAllEmail = ref();
    const modalStatusAllSms = ref();

    async function enviarAllEmail (defaultMessage = false){
      const refModal = document.getElementById("modal-status-all-email");
      if(refModal){
        document.getElementById("layout_content")?.appendChild(refModal);
      }
      modalStatusAllEmail.value = new Modal(refModal, {keyboard: false, backdrop: 'static'});
      modalStatusAllEmail.value.show();

      showSelect.value = false;
      showBtnSendEmail.value = false;
      showBtnStatusSendEmail.value = true;
      itensSelecteds.value = itensSelecteds.value.filter(item => item.info.email).map((item) => ({ ...item, status: "Pendente" }));

      // let varAux = 1000;

      await itensSelecteds.value.forEach((item, index) => {        
        const mensagem = defaultMessage ? null : formMessageAll.value;

        const postData = {
          marca: item.marca,
          email: item.info.email,
          mensagem: mensagem ? mensagem : null,
          cliente: item.cliente,
          codOrcamento: data.itemSelected.codOrcamento,
          codConcessionaria: item.codConcessionaria,
          codChecklist: item.codChecklist,
          checklist: item.checklist,
          tipo: 4,
        };

        // varAux += 1000;

        enviarMensagem(postData).then(() => item.status = "Realizado").catch(() => item.status = "Erro");
        // item.status = "Realizado"
        if(itensSelecteds.value.length - 1 == index){
          showBtnConfirmarSendEmail.value = true;
          
          showTimeAlert("Os Email's foram enviados!");
        };
      });
    };

    async function enviarWhatsApp (defaultMessage = false){
      let telefone = defaultMessage ? data.itemSelected.telefone : formTelefone.value;
      telefone = telefone.replaceAll(" ", ""); //retira espaços ex: (31)33333333
      telefone = telefone.replaceAll("-", ""); //retira espaços ex: (31)33333333
			telefone = telefone.replaceAll(")", ""); //retira parenteses ex: (3133333333
			telefone = telefone.replaceAll("(", ""); //retira

      const mensagem = defaultMessage ? null : formMessage.value;

      const postData = {
        marca: data.itemSelected.marca,
        telefone: telefone,
        mensagem: mensagem ? mensagem : null,
        cliente: data.itemSelected.cliente,
        codConcessionaria: data.itemSelected.codConcessionaria,
        codChecklist: data.itemSelected.codChecklist,
        checklist: data.itemSelected.checklist,
        codOrcamento: data.itemSelected.codOrcamento,
        tipo: 5,
      };

      loadingButton.value = true;
      try{
        const response = await enviarMensagem(postData);
        showTimeAlert('O link do whatsapp foi gerado!');
        const elementModal = document.getElementById("modal-wpp");
        hideModal(elementModal)
        data.itemSelected.envioWPP = true;
        window.open(response);
      } catch (e){
        showTimeAlert('Ocorreu um erro ao gerar o link do whatsapp', 'error');
      }
      loadingButton.value = false;

    };

    return { 		dominio_orcamento,animate, geraMessage, moment, historicoContatoPeriodo, dadosMotivoReprovacao, dadosHistoricoContato, filtraClientes, clientesSemEmail, clientesSemTelefone, loadingButton, exportarXLSX, ptBr, showConfirmAlert, showTimeAlert, itensSelecteds, formMessageAll, formMessage, formTelefone, formEmail, emailError, telefoneError, showSelect, showBtnSendSMS, showBtnStatusSendSMS, showBtnSendEmail, showBtnStatusSendEmail, showBtnConfirmarSendSMS, showBtnConfirmarSendEmail, ...toRefs(data), headers, headersSMS, headersEmail, goTo, loadingTable, motivoSelected, pecaSelected, enviarSMS, enviarEmail, enviarAllSMS, enviarAllEmail, enviarWhatsApp, resetSelectSMS, resetSelectEmail, codConcessionaria, itensReprovados };
  }
});
