
import { ContentLoader } from 'vue-content-loader';
import { defineComponent, onMounted, ref, watch } from "vue";
import ApexChart from "apexcharts";

export default defineComponent({
  name: "MotivosReprovacaoCard",
  components: { ContentLoader },
  props: {
    loadingTable: Boolean,
    data: Array,
  },
  setup(props) {
    // const labels = ["Desejo negociar", "Preço", "Não desejo", "Realizar em outra loja", "Realizar na próxima", "Garantia"]
    const colors = ["#007BFF", "#5BAAFF", "#EB1930", "#FD6979", "#FD99A3", "#ffc6cc"];
    const labels:any = ref([]);
    const series:any = ref([]);
    //declaração de variaveis//
    let chartInstance;
    let chartMobileInstance;
    const image = "https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/shapes/abstract-1.svg";
 
    function geraOptions(labelsRef, refColors, isMobile = false) {
      const newOptions = {
        dataLabels: {
          enabled: true,
        },
        chart: {
          width: '100%',
          height: 230,
          type: 'pie',
          options: "chart",
          offsetY: -10,
          series: [],
        },
        stroke: {
          width: 0
        },
        legend: {
          horizontalAlign: 'left', 
          position: isMobile ? 'bottom' : 'right'
        },
        labels: labelsRef,
        colors: refColors,
        fill: {
          colors: refColors,
        },
      }

      return newOptions;
    }

    watch(() => props.data, ( ) => {
      if(props.data){
        const motivos:any = props.data;
        motivos.pop();
        const motivosWithValue = motivos.filter((motivo, index) => motivo.quantidade > 0);

        series.value = motivosWithValue.map(motivo => motivo.quantidade);
        labels.value = motivosWithValue.map(motivo => {
          let nomeTratado = motivo.nome[0].toUpperCase() + motivo.nome.slice(1).toLowerCase();
          return nomeTratado;
        });

        let colorsData:any = [];
        motivos.forEach((motivo, index) => {
          if(motivo.quantidade > 0){
            colorsData.push(colors[index]);
          }
        });

        setTimeout(() => {
          chartInstance.updateOptions(geraOptions(labels.value, colorsData));
          chartInstance.updateSeries(series.value);
          chartMobileInstance.updateOptions(geraOptions(labels.value, colorsData, true));
          chartMobileInstance.updateSeries(series.value);
        }, 500);
      }
    });
  
    onMounted(async () => {
      const options = geraOptions(["Desejo negociar", "Preço", "Não desejo", "Realizar em outra loja", "Realizar na próxima", "Garantia"], ["#007BFF", "#449EFF", "#EB1930", "#F44C5E", "#FF6071", "#ffc6cc"]);
      chartInstance = new ApexChart(document.querySelector("#grafico-motivo"), {
        ...options,
        series: [0, 0, 0, 0, 0],
      });
      chartMobileInstance = new ApexChart(document.querySelector("#grafico-mobile-motivo"), {
        ...options,
        series: [0, 0, 0, 0, 0],
      });
      chartInstance.render();
      chartMobileInstance.render();
    });

    return {
      image,
      colors,
      labels,
      series,
    };
  },
});
